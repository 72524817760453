import type { OnDestroy, OnInit } from '@angular/core';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { TuiAlertService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@taiga-ui/polymorpheus';

@Component({
	selector: 'changelog-message',
	standalone: true,
	imports: [RouterLink],
	template: 'Update available. <br>Check the <a routerLink="/changelog">Changelist</a> <- clickable',
	styles: [
		`
			:host {
				text-align: center;
			}

			a {
				text-decoration: dotted white 2px;

				&:hover {
					text-decoration: none;
				}
			}
		`,
	],
})
export class ChangelogMessageComponent {}

@Component({
	selector: 'app-update-alerts',
	template: '',
	standalone: true,
	styles: [''],
})
export class UpdateComponent implements OnInit, OnDestroy {
	private readonly notificationsService = inject(TuiAlertService);
	private worker?: Worker;

	private open = false;

	ngOnInit(): void {
		if (typeof Worker === 'undefined') {
			return;
		}

		this.worker = new Worker(new URL('./update.worker', import.meta.url));

		this.worker.onmessage = (msg) => {
			if (!this.open && msg.data.includes('version')) {
				this.open = false;
				this.show();
			}
		};
		this.worker.postMessage('start');
	}

	ngOnDestroy(): void {
		this.worker?.postMessage('destroy');
		this.worker?.terminate();
	}

	show(): void {
		this.open = true;

		this.notificationsService
			.open(new PolymorpheusComponent(ChangelogMessageComponent), {
				label: 'Reload page',
				appearance: 'warning',
				autoClose: 2000,
				closeable: false,
			})
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe();
	}

	readonly destroyRef = inject(DestroyRef);
}
