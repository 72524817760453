import type * as CookieConsent from 'vanilla-cookieconsent';

export const COOKIE_SETTINGS: CookieConsent.CookieConsentConfig = {
	root: 'body',
	autoShow: true,
	disablePageInteraction: true,
	hideFromBots: true,
	mode: 'opt-in',
	revision: 0,

	cookie: {
		name: 'cc_cookie',
		domain: location.hostname,
		path: '/',
		sameSite: 'Lax',
		expiresAfterDays: 365,
	},

	// https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
	guiOptions: {
		consentModal: {
			layout: 'cloud inline',
			position: 'bottom center',
			equalWeightButtons: true,
			flipButtons: false,
		},
		preferencesModal: {
			layout: 'box',
			equalWeightButtons: true,
			flipButtons: false,
		},
	},

	categories: {
		necessary: {
			enabled: true, // эта категория включена по умолчанию
			readOnly: true, // эта категория не может быть отключена
		},
		analytics: {
			autoClear: {
				cookies: [
					{
						name: /^_ga/, // regex: все куки с именем, начинающимся с '_ga'
					},
					{
						name: '_gid', // точное имя куки
					},
				],
			},

			services: {
				ga: {
					label: 'Google Analytics',
					onAccept: () => {},
					onReject: () => {},
				},
				youtube: {
					label: 'Youtube Embed',
					onAccept: () => {},
					onReject: () => {},
				},
			},
		},
		ads: {},
	},

	language: {
		default: 'en',
		translations: {
			en: {
				consentModal: {
					title: 'We use cookies',
					description: 'Choose the values.',
					acceptAllBtn: 'Accept all',
					acceptNecessaryBtn: 'Reject all',
					showPreferencesBtn: 'Manage Individual preferences',
					// closeIconLabel: 'Reject all and close modal',
					footer: `
                      <a href="./agreement" target="_blank">Privacy Policy</a>
                    `,
				},
				preferencesModal: {
					title: 'Manage cookie preferences',
					acceptAllBtn: 'Accept all',
					acceptNecessaryBtn: 'Reject all',
					savePreferencesBtn: 'Accept current selection',
					closeIconLabel: 'Close modal',
					serviceCounterLabel: 'Service|Services',
					sections: [
						{
							title: 'Your Privacy Choices',
							description: `In this panel you can express some preferences\n
									 related to the processing of your personal information. You may\n 
									 review and change expressed choices at any time by resurfacing 
									 this panel via the provided link. To deny your consent
									  to the specific processing activities described below, 
									  switch the toggles to off or use the
									   “Reject all” button and confirm you want to save your choices.`,
						},
						{
							title: 'Strictly Necessary',
							description:
								// eslint-disable-next-line max-len
								'These cookies are essential for the proper' + ' functioning of the website and cannot be disabled.',

							linkedCategory: 'necessary',
						},
						{
							title: 'Performance and Analytics',
							description:
								'These cookies collect ' +
								'information about how ' +
								'you use our website.' +
								' All of the data is anonymized and cannot be used to identify you.',
							linkedCategory: 'analytics',
							cookieTable: {
								caption: 'Cookie table',
								headers: {
									name: 'Cookie',
									domain: '',
									desc: 'Description',
								},
								body: [
									{
										name: '_ga',
										domain: location.hostname,
										desc: 'Description 1',
									},
									{
										name: '_gid',
										domain: location.hostname,
										desc: 'Description 2',
									},
								],
							},
						},
						{
							title: 'Targeting and Advertising',
							description:
								'These cookies are used to make advertising messages more ' +
								'relevant to you and your interests. The intention is to display ' +
								'ads that are relevant and engaging for the individual user and' +
								' thereby more valuable for publishers and third party advertisers.',
							linkedCategory: 'ads',
						},
					],
				},
			},
		},
	},
};
