import type { OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { Component, computed, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, NavigationError, Router, RouterModule } from '@angular/router';
import { HeaderComponent, PrintComponent, ScrollToTopComponent } from '@core/components';
import { CookieConsentOpenComponent } from '@core/components/cookie/cookie-consent-open.component';
import { FooterComponent } from '@core/components/footer/footer.component';
import { TuiRoot } from '@taiga-ui/core';
import {
	AudioPlaybackVisualizerComponent,
	DrawerHostComponent,
	OnlineInfoComponent,
	ScrollProgressComponent,
} from '@ui/components';
import { OnlineService } from '@ui/services';
import { filter, take, tap } from 'rxjs';
import * as CookieConsent from 'vanilla-cookieconsent';
import { COOKIE_SETTINGS } from './cookie-settings';
import { UpdateComponent } from './update/update.component';

@Component({
	standalone: true,
	imports: [
		RouterModule,
		HeaderComponent,
		DrawerHostComponent,
		TuiRoot,
		UpdateComponent,
		FooterComponent,
		ScrollProgressComponent,
		CookieConsentOpenComponent,
		PrintComponent,
		ScrollToTopComponent,
		OnlineInfoComponent,
		AudioPlaybackVisualizerComponent,
		CdkDrag,
	],
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
	animations: [
		trigger('slideTop', [
			transition(':enter', [
				style({ transform: 'translateY(-100%)', opacity: 0 }), // Start above with zero opacity
				// eslint-disable-next-line max-len
				animate('300ms ease-out', style({ transform: 'translateY(0)', opacity: 1 })), // Move to normal position and appear
			]),
			// Leave animation: slide out upwards
			transition(':leave', [
				style({ transform: 'translateY(0)', opacity: 1 }), // Start from normal position
				// eslint-disable-next-line max-len
				animate('300ms ease-out', style({ transform: 'translateY(-100%)', opacity: 0 })), // Move upwards and fade out
			]),
		]),
	],
})
export class AppComponent implements OnInit {
	private readonly destroyRef = inject(DestroyRef);
	private readonly router = inject(Router);
	private readonly onlineService = inject(OnlineService);
	readonly isOnline = computed(() => {
		return this.onlineService.isOnline();
	});

	async ngOnInit() {
		this.removeLoader();
		await CookieConsent.run({ ...COOKIE_SETTINGS });
	}

	private removeLoader(): void {
		this.router.events
			.pipe(
				filter((ev) => {
					return ev instanceof NavigationEnd || ev instanceof NavigationError;
				}),
				take(1),
				tap(() => document.querySelector('.js-app-loader')?.remove()),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe();
	}
}
