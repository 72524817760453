<div class="footer">
	<div class="flexible-container">
		<div class="row">

			<div class="site-name">
				It's Deprecated
			</div>

			<div class="power-dates">
					{{powerDate()}}
			</div>

			<div class="created-with">
				<span>
					Created with:
				</span>

				<div class="lib">
					<b>TaigaUI </b> by <b>T-Bank</b>
				</div>
			</div>
		</div>
	</div>
	<div class="version">
		<app-version></app-version>
	</div>
</div>