<app-scroll-progress></app-scroll-progress>
<tui-root >
	<app-header class="header"></app-header>
	@if(isOnline()) {
		<app-online @slideTop></app-online>
	}

	<div class="flexible-container">
		<main>
			<router-outlet></router-outlet>
		</main>
	</div>

	<app-footer></app-footer>

	<ng-container ngProjectAs="tuiOverContent">
		<!-- Content over app content -->
	</ng-container>
	<app-update-alerts></app-update-alerts>

	<app-drawer-host></app-drawer-host>
	<app-drawer-host></app-drawer-host>
	<app-cookie-consent-button></app-cookie-consent-button>
	<app-print-button></app-print-button>

	<app-playback-visualizer
		cdkDrag
		url="/assets/audio/1.mp3"
		class="playback"
	> </app-playback-visualizer>
<app-scroll-to-top></app-scroll-to-top>
</tui-root>
